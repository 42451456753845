import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { FaSearch, FaArrowLeft} from 'react-icons/fa';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import Layout from './Layout';
import { useHistory } from 'react-router-dom';

const MemberUpdate = (props: any) => {

    const FormatDate = (date: any) => {
        let dateStr = new Date(date);
        var year = dateStr.getFullYear();
        var month = (1 + dateStr.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = dateStr.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return year + '-' + month + '-' + day;
    }

    const nextOfKinLag = props.location.state.nextOfKin.filter((lag: { location: string }) => lag.location == 'LAGOS')[0];
    const nextOfKinOrig = props.location.state.nextOfKin.filter((lag: { location: string }) => lag.location == 'Origin')[0];
        
    const [membersData, setMembersData] = useState([]);
    const [nextOfKinData, setNextOfKinData] = useState([]);
    const [mems, setMems] = useState([]);
    const [bioDataStyle, setBioDataStyle] = useState({ width: '50%',borderBottom: 'solid 7px #ffffff', cursor: 'pointer'});
    const [paymentInfoStyle, setPaymentInfoStyle] = useState({ width: '50%', borderBottom: 'none', cursor: 'pointer' });
    const [bioDataPanel, setBioDataPanel] = useState({ display: 'block' });
    const [paymentInfoPanel, setPaymentInfoPanel] = useState({ display: 'none' });

    const [name, setName] = useState(props.location.state.memberDetails.name);
    const [phone, setPhone] = useState(props.location.state.memberDetails.phone);
    const [birthDay, setBirthDay] = useState(props.location.state.memberDetails.birthAnniversary);
    const [marriage, setMarriage] = useState(props.location.state.memberDetails.weddingAnniversary);
    const [email, setEmail] = useState(props.location.state.memberDetails.email);
    const [dateJoined, setDateJoined] = useState(FormatDate(props.location.state.memberDetails.dateJoined));
    const [residentialAddress, setResidentialAddress] = useState(props.location.state.memberDetails.residentialAddress);
    const [memberStatus, setMemberStatus] = useState(props.location.state.memberDetails.memberStatus);

    const [nextOfKinLagosName, setNextOfKinLagosName] = useState(nextOfKinLag.name);
    const [nextOfKinLagosPhone, setNextOfKinLagosPhone] = useState(nextOfKinLag.phone);
    const [nextOfKinLagosAddress, setNextOfKinLagosAddress] = useState(nextOfKinLag.address);

    const [nextOfKinOriginName, setNextOfKinOriginName] = useState(nextOfKinOrig.name);
    const [nextOfKinOriginPhone, setNextOfKinOriginPhone] = useState(nextOfKinOrig.phone);
    const [nextOfKinOriginAddress, setNextOfKinOriginAddress] = useState(nextOfKinOrig.address);

    let history = useHistory();
    const navigate = (path: string) => {

        history.push({
            pathname: path
        })
    }
  
  
    const changeTab = (text:string) => {
        if (text === 'bioData') {
            setPaymentInfoStyle({ width: '50%', borderBottom:'none', cursor:'pointer'})
            setBioDataStyle({ width: '50%', borderBottom: 'solid 7px #ffffff', cursor: 'pointer' })

            setBioDataPanel({ display: 'block' });
            setPaymentInfoPanel({display:'none'})
        }
        else if (text === 'paymentInfo') {
            setBioDataStyle({ width: '50%', borderBottom:'none' , cursor:'pointer'})
            setPaymentInfoStyle({ width: '50%', borderBottom: 'solid 7px #ffffff', cursor: 'pointer' });

            setBioDataPanel({ display: 'none' });
            setPaymentInfoPanel({ display: 'block' })
        }
       
    }
    const saveBioData = () => {
        const bioDataObj:any = {};
        const basicInfoObj:any = {};
        const NextOfKin:any = [];
        const nextOfKinLagos:any = {}
        const nextOfKinOrigin:any = {}

        basicInfoObj.Id= props.location.state.memberId;
        basicInfoObj.Name = name;
        basicInfoObj.Phone = phone;
        basicInfoObj.BirthAnniversary = birthDay;
        basicInfoObj.WeddingAnniversary = marriage;
        basicInfoObj.ResidentialAddress = residentialAddress;
        basicInfoObj.Email = email;
        basicInfoObj.DateJoined =FormatDate(dateJoined);
        basicInfoObj.MemberStatus = memberStatus;

        nextOfKinLagos.MembersId = props.location.state.memberId;
        nextOfKinLagos.Name = nextOfKinLagosName;
        nextOfKinLagos.Phone = nextOfKinLagosPhone;
        nextOfKinLagos.Address = nextOfKinLagosAddress;
        nextOfKinLagos.Location = 'LAGOS';

        nextOfKinOrigin.MembersId = props.location.state.memberId;
        nextOfKinOrigin.Name = nextOfKinOriginName;
        nextOfKinOrigin.Phone = nextOfKinOriginPhone;
        nextOfKinOrigin.Address = nextOfKinOriginAddress;
        nextOfKinOrigin.Location = 'Origin';

        NextOfKin.push(nextOfKinLagos);
        NextOfKin.push(nextOfKinOrigin);

        bioDataObj.memberData = basicInfoObj;
        bioDataObj.NextOfKin = NextOfKin;

        //alert(JSON.stringify(bioDataObj));

        axios({
            method: 'post',
            url: '/Members/MemberUpdate',
            data: bioDataObj
        }).then(result => {
            if (result.data.success) {
                alert('Record was successfully updated');
            }
            else {
                alert(result.data.message);
            }
                       
        }).catch(err => {
            alert(err.message);
        });

    }
    return (
        <Layout>
    <div style={{maxHeight:'85vh', overflowY:'auto', padding:'8px'}}>
              
                <div style={{ backgroundColor: '#4169e1', padding: '8px', color: 'white', display:'flex' }}>
                    <div style={{  width: '80%' }} > <strong> Update Members Record</strong></div>
                    <div style={{ width: '20%', textAlign: 'right' }} onClick={()=>navigate('/Admin') } > <FaArrowLeft /></div>
            </div>
            <br/>
                <div style={{ display: 'flex', backgroundColor: '#4169e1', padding: '8px', color: 'white' }}>
                    <div style={ bioDataStyle} onClick={ ()=>changeTab('bioData')}>Bio Data</div>
                    <div style={ paymentInfoStyle} onClick={() => changeTab('paymentInfo')}>Payment Info</div>
                </div>

                <div style={bioDataPanel}>
                    <br />  <h6>  BIO DATA </h6>
                    <input type="text" id="name" value={name} className="form-control" placeholder="Member Name" onChange={(e: any) => setName(e.target.value)} /> <br/>
                    <input type="text" id="phone" className="form-control" value={phone} placeholder="Phone" onChange={(e: any) => setPhone(e.target.value)} /><br />
                    <input type="text" id="birthDay" className="form-control" value={birthDay} placeholder="Birth Anniversary" onChange={(e: any) => setBirthDay(e.target.value)}/><br />
                    <input type="text" id="marriage" className="form-control" value={marriage} placeholder="Wedding Anniversary" onChange={(e: any) => setMarriage(e.target.value)} /><br />
                    <input type="text" id="email" className="form-control" value={email} placeholder="Email" onChange={(e: any) => setEmail(e.target.value)} /><br />
                    <input type="text" id="dateJoined" className="form-control" value={dateJoined} placeholder="Date Joined MM/DD/YYYY" onChange={(e: any) => setDateJoined(e.target.value)} /><br />
                    <select onChange={ (e)=>setMemberStatus(e.target.value)} className="form-control">
                        <option>Select</option>
                        <option selected={memberStatus=='Active'?true:false }>Active</option>
                        <option selected={memberStatus == 'Relocated' ? true : false}>Relocated</option>
                        <option selected={memberStatus == 'Left Church' ? true : false}>Left Church</option>
                        <option selected={memberStatus == 'Dead' ? true : false}>Dead</option>
                        </select>
                    <textarea id='residentialAddress' className="form-control" value={residentialAddress} placeholder="Residential Address" onChange={(e: any) => setResidentialAddress(e.target.value)}></textarea>
                    <br />
                    <br />
                    <h6>  NEXT OF KIN - LAGOS </h6>
                    <input type="text" id="nextOfKinLagos-name" value={nextOfKinLagosName} className="form-control" placeholder=" Name" onChange={(e:any)=>setNextOfKinLagosName(e.target.value)} /> <br />
                    <input type="text" id="nextOfKinLagos-phone" value={nextOfKinLagosPhone} className="form-control" placeholder=" Phone" onChange={(e: any) => setNextOfKinLagosPhone(e.target.value)} /> <br />
                    <textarea id='nextOfKinLagos-address' className="form-control" value={nextOfKinLagosAddress} placeholder="Residential Address" onChange={(e: any) => setNextOfKinLagosAddress(e.target.value)}></textarea>
                    <br />

                    <br />
                    <h6>  NEXT OF KIN - ORIGIN </h6>
                    <input type="text" id="nextOfKinOrigin-name" value={nextOfKinOriginName} className="form-control" placeholder=" Name" onChange={(e: any) => setNextOfKinOriginName(e.target.value)} /> <br />
                    <input type="text" id="nextOfKinOrigin-phone" value={nextOfKinOriginPhone} className="form-control" placeholder=" Phone" onChange={(e: any) => setNextOfKinOriginPhone(e.target.value)} /> <br />
                    <textarea id='nextOfKinOrigin-address' className="form-control" value={nextOfKinOriginAddress} placeholder="Residential Address" onChange={(e: any) => setNextOfKinOriginAddress (e.target.value)}></textarea>
                    <br />
                    <button className="btn btn-primary float-right" onClick={ ()=>saveBioData()}>Save</button>
                    </div>
                <div style={paymentInfoPanel}> <br /><h6> PAYMENT INFO</h6></div>
            </div>
       </Layout>
)};

export default connect()(MemberUpdate);
