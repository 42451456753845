import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  Member  from './Member';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import Layout from './Layout';
import { useHistory } from 'react-router-dom';
const Home = () => {


    const [membersData, setMembersData] = useState([]);
    const [nextOfKinData, setNextOfKinData] = useState([]);
    const [mems, setMems] = useState([]);
    let history = useHistory();
       
    if ( localStorage.getItem('User') ==null)
        {
            history.push({
                pathname: '/'
            })

        }

    useEffect(() => {
        axios(
            '/Members/AllMembers',
        ).then(result => {
            setMembersData(result.data.data.membersData);
            setNextOfKinData(result.data.data.membersNextOfKinData);
            setMems(result.data.data.membersData);
           
            //setRequests(result.data.data);
        });

    }, [])

    const SearchChange = (text:string) => {
        if (text === '') {
            setMembersData(mems);
        }
        else {
            setMembersData(membersData.filter((mem: {name:string}) => mem.name.toLowerCase().includes(text.toLowerCase())));
        }
       
    }
    return (
        <Layout>
    <div style={{maxHeight:'85vh', overflowY:'auto', padding:'8px'}}>
            <br />          
                <div style={{ backgroundColor: '#4169e1', padding: '8px', color: 'white' }}>
                <div style={{ display: 'inline-block', width: '50%' }} > <strong> Members Register</strong></div>
                <div style={{ display: 'inline-block', width: '50%' }} > 
                    <InputGroup>
                        <input type='text' className='form-control custom-form-control' placeholder='Search member' onChange={(event) => SearchChange(event.target.value)} style={{ borderRight: '0', fontSize: '12px', marginTop: '0', marginBottom: '0' }} />

                    <InputGroupText style={{ borderRadius: '0', backgroundColor: 'white', borderLeft: '0' }}>
                        <FaSearch />
                    </InputGroupText>
                </InputGroup>
                </div>
            </div>
            <br/>
            {   
                    membersData.map((member: {id:number}) => (

                    <Member memberData={member} nextOfKin={nextOfKinData.filter((kin: {membersId:number}) => kin.membersId === member.id )} navLink='/member-update'/>
                ))
       
            }
            </div>
       </Layout>
)};

export default connect()(Home);
