import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  Member  from './Member';
import axios from 'axios';
import { FaSearch, FaArrowAltCircleLeft } from 'react-icons/fa';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import Layout from './Layout';
import MemberPayments from './MemberPayments';
import { useHistory } from 'react-router-dom';
const MemberLevyPayments = (props:any) => {

    const [membersLevyData, setMembersLevyData] = useState([]);
    const [years, setYears] = useState([]);

    let history = useHistory();
    const navigate = (path: string) => {
        history.push({
            pathname: path
        })
    }
  
    useEffect(() => {
        axios(
            '/Members/PaymentsByMemberId?Id=' + props.location.state.memberId,
        ).then(result => {
            const allYear = result.data.data.membersLevyPayments.map((levy: {yearPaidFor:string}) => levy.yearPaidFor)

            setMembersLevyData(result.data.data.membersLevyPayments);
            setYears(allYear.filter((item: any, index: any) => allYear.indexOf(item) === index));
        });

    }, [])

    return (
        <Layout>
    <div style={{maxHeight:'85vh', overflowY:'auto', padding:'8px'}}>                  
                <div style={{ backgroundColor: '#4169e1', padding: '8px', color: 'white' }}>
                    <div style={{ display: 'inline-block', width: '70%' }}> <strong> {props.location.state.memberName.toUpperCase()}</strong>  </div>  <div style={{ display: 'inline-block', width: '28%', fontSize: '16px', color: 'white', textAlign: 'right', fontWeight: 'bolder' }}><FaArrowAltCircleLeft style={{ display: 'inline-block', cursor: 'pointer' }} onClick={ ()=>navigate('/Home')} /></div>
                             
            </div>
            <br/>
                {
                    years.map(a => (
                        <MemberPayments payment={membersLevyData.filter((levy: { yearPaidFor: any }) => levy.yearPaidFor === a)} year={a} />
                                    )                    
                              )
                   
                }
            </div>
       </Layout>
)};

export default connect()(MemberLevyPayments);
