import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Landing from './components/Landing';
import MemberLevy from './components/MemberLevyPayments';
import LogIn from './components/LogIn';
import Admin from './components/Admin';
import MemberUpdate from './components/MemberUpdate';



import './custom.css'

export default () => (
    <>
        <Route exact path='/' component={Landing} />
        <Route exact path='/Home' component={Home} />
        <Route exact path='/Admin' component={Admin} />
        <Route exact path='/LogIn' component={LogIn} />
        <Route exact path='/member-update' component={MemberUpdate} />
        <Route exact path='/member-levy' component={MemberLevy} />
    </>
);
