import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { applyMiddleware } from 'redux';

interface PaymentType {
    payment: any
    year: any
}
const MemberPayments = ( Payment:PaymentType) => {
 

    return (       
                
                    <>
           
                     <div className="card">
            
                <div className="card-body" style={{ padding: '4px', marginRight: '2px', marginLeft: '2px' }}>
                    <div>
                            <span style={{ width: '68%', display: 'inline-block' }}>
                                {
                                    Payment.payment.map((lev: {levy:any,fullPayment:any}) => (
                                    <div>                                   
                                            <div><strong>{lev.levy} :</strong> {lev.fullPayment==='Yes'?'Paid':'Half Payment'} </div>
                                    </div>
                                    ))
                            
                                }  
                              </span>
                            <span style={{ width: '30%', display: 'inline-block', paddingLeft: '4px',fontSize:'2em', height:'50px',textAlign:'right' }}>{Payment.year}</span>
                    </div>
                    </div>
                    </div>
                     <br /> 
                    </>
                )
       
         
};

export default connect()(MemberPayments);
