import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FaSignOutAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

export default function NavMenu() {

    let history = useHistory();
    const navigate = (path: string) => {

        localStorage.removeItem('User');

        history.push({
            pathname: path
        })
    }
        return (
            <header style={{ backgroundColor:'#4169e1'}}>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow" light >
                    <div className="row" style={{ display: 'flex',width:'100%' }} >
                        <div style={{ width: '80%', display: 'inline-block' }}> <img src="/logo.png" style={{marginLeft:'10px'}} /> </div>
                        <div style={{ width: '20%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}><FaSignOutAlt style={{ color: 'white', fontSize: '18px' }} onClick={()=>navigate('/') } /></div>
                    </div>                   
                </Navbar>
              
            </header>
        );
    }

    
