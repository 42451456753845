import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  Member  from './Member';
import axios from 'axios';
import { FaBackward } from 'react-icons/fa';
import { InputGroup, InputGroupText, Input, Nav } from 'reactstrap';
import { useHistory } from 'react-router-dom';
const LogIn = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    let history = useHistory();
    const navigate = (path: string) => {
        if (userName && password) {
             axios(
                 '/Members/LogIn?UserName=' + userName + '&Password=' + password, 
                    ).then(result => {
                       
                        if (result.data.success) {

                            localStorage.setItem('User', result.data.message);

                            history.push({

                                pathname: '/Admin'

                            })
                        }
                        else {

                            alert(result.data.message);
                        }
                    });
        }
        else {
            alert('Invalid username or password'); return false;
        }
                     
    } 

    return (
        <div style={{ height: '100vh', display: 'flex', overflowY: 'auto', padding: '8px', backgroundColor: '#eeeeee', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src='icon192x192.png' width="90" style={{position:'fixed', top:'30px'}} />
            
            <div className="form-control" style={{ display: 'flex' }}><span style={{ width: '30%' }}>User Name</span>  <input type="text" id="UserName" style={{ width: '80%', border: '0', outline: '0 none' }} onChange={(e:any)=>setUserName(e.target.value) } /> </div>
            <br />
            <div className="form-control" style={{ display: 'flex' }}><span style={{ width: '30%' }}>Password</span>  <input type="password" id="password" style={{ width: '80%', border: '0', outline: '0 none' }} onChange={(e: any) => setPassword(e.target.value)}  /> </div>
            <br />
            <button className='btn btn-success' onClick={ ()=>navigate('')} >
                Submit
            </button>
        </div>
)};

export default connect()(LogIn);
