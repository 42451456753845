import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
interface MembersType {
    memberData: any
    nextOfKin: any,
    navLink:any
}
const Member = ( member:MembersType) => {
   // alert(JSON.stringify(nextOfKin));

    let history = useHistory();
    const navigate = (path: string) => {
        history.push({
            pathname: path,
            state: {memberDetails:member.memberData, nextOfKin:member.nextOfKin, memberId: member.memberData.id, memberName: member.memberData.name }
        })
    }

    const [basicInfo, setBasicInfo] = useState({display:'block'});
    const [nextOfKinLagos, setNextOfKinLagos] = useState({display:'none'});
    const [nextOfKinOrigin, setNextOfKinOrigin] = useState({ display: 'none' });

    const [basicInfoTab, setBasicInfoTab] = useState('ActiveTab');
    const [nextOfKinLagosTab, setNextOfKinLagosTab] = useState('');
    const [nextOfKinOriginTab, setNextOfKinOriginTab] = useState('');

    const nextOfKinLag =member.nextOfKin.filter((lag: {location:string}) => lag.location == 'LAGOS')[0];
    const nextOfKinOrig =member.nextOfKin.filter((lag: {location:string} )=> lag.location == 'Origin')[0];

    const tabChange = (tabClicked:any) => {
        switch (tabClicked) {
            case 'basicInfo':
                setBasicInfoTab('ActiveTab');
                setNextOfKinLagosTab('');
                setNextOfKinOriginTab('');

                setBasicInfo({ display: 'block' });
                setNextOfKinLagos({ display: 'none' });
                setNextOfKinOrigin({ display: 'none' });

                break;
            case 'lagos':
                setBasicInfoTab('');
                setNextOfKinLagosTab('ActiveTab');
                setNextOfKinOriginTab('');

                setBasicInfo({ display: 'none' });
                setNextOfKinLagos({ display: 'block' });
                setNextOfKinOrigin({ display: 'none' });
                break;
            case 'origin':
               
                setBasicInfoTab('');
                setNextOfKinLagosTab('');
                setNextOfKinOriginTab('ActiveTab');

                setBasicInfo({ display: 'none' });
                setNextOfKinLagos({ display: 'none' });
                setNextOfKinOrigin({ display: 'block' });
            break;
            }
    }

    return (       
                
                    <>
            <div style={{ backgroundColor:'#4169e1',color:'white', padding:'2px', textAlign:'center'}}>
                <div className={'tabStyle ' + basicInfoTab} onClick={() => tabChange('basicInfo')}>Basic Info</div>
                <div className={'tabStyle ' + nextOfKinLagosTab} onClick={() => tabChange('lagos')}>Next Of Kin(Lagos)</div>
                <div className={'tabStyle ' + nextOfKinOriginTab} onClick={() => tabChange('origin')}>Next Of Kin(Origin)</div>
           </div>
                     <div className="card">
            
                <div className="card-body" style={{ padding: '4px', marginRight: '2px', marginLeft: '2px', cursor: 'pointer' }} onClick={() => navigate(member.navLink)}>
                    <div style={basicInfo }>
                         <div style={{ width: '70%',display:'inline-block' }}>
                                    <div><strong>Name :</strong> {member.memberData.name} </div>
                        <div><strong>Phone :</strong> {member.memberData.phone} </div>
                        <div><strong>Marriage Anniversary : </strong>{member.memberData.weddingAnniversary } </div>
                        <div><strong>Birth Anniversary :</strong>{member.memberData.birthAnniversary} </div>
                        <div><strong>Email : </strong> {member.memberData.email}</div>
                            <div><strong>Residential Address : </strong>{member.memberData.residentialAddress} </div></div>
                  
                <div style={{ width: '20%' , display: 'inline-block', paddingLeft:'4px'}}><img src='/myPix.jpeg' style={{ width: '100%'}} /></div>
                    </div>
                    <div style={nextOfKinLagos}>
                        <div><strong>Name :</strong> {nextOfKinLag?nextOfKinLag.name:''}  </div>
                        <div><strong>Phone :</strong> {nextOfKinLag ? nextOfKinLag.phone : ''} </div>
                        <div><strong>Address : </strong> {nextOfKinLag ? nextOfKinLag.address : ''}</div>
                        </div>
                    <div style={nextOfKinOrigin}>
                        <div><strong>Name :</strong> {nextOfKinOrig ? nextOfKinOrig.name : ''}  </div>
                        <div><strong>Phone :</strong> {nextOfKinOrig ? nextOfKinOrig.phone : ''} </div>
                        <div><strong>Address : </strong> {nextOfKinOrig ? nextOfKinOrig.address : ''}</div>
                        </div>

            </div>
        </div>
                     <br /> 
                    </>
                )
       
         
};

export default connect()(Member);
