import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import  Member  from './Member';
import axios from 'axios';
import { FaBackward } from 'react-icons/fa';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
const Landing = () => { 

    let history = useHistory();
    const navigate = (path:string) => {
       
        history.push({
            pathname: path
        })
    } 

    return (
        <div style={{backgroundColor:'#eee', height:'100vh', paddingTop:'10px'}}>
            <img src="/icon192x192.png" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100px' }} />
            <div style={{textAlign:'center', fontSize:'30px', paddingTop:'20px'}}>Women Of Peace <br/> Association</div>
        <div style={{ position:'fixed', bottom:0, width:'100%' }}>
            <button className='btn btn-primary' style={{ width: '100%', height: '100px', fontSize: '3em', borderRadius: 0}} onClick={ ()=>navigate('/Home')}>Members Register</button>
            <button className='btn btn-success' style={{ width: '100%', height: '100px', fontSize: '3em',borderRadius:0}} onClick={() => navigate('/Login')}>Admin</button>
           
            </div>
            </div>
)};

export default connect()(Landing);
